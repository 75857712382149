<template>
  <MyDataTable
    :title="$route.meta.title + ' ها'"
    :subtitle="'مدیریت ' + $route.meta.title + ' ها'"
    :addtitle="'ایجاد ' + $route.meta.title + ' جدید'"
    :addpageRoute="
      `${$route.path}/create${
        $route.query.page ? '?page=' + $route.query.page : ''
      }`
    "
    :headers="headers"
    :items="items"
    :loading="loading"
    :pagesLength="pagesLength"
    @onPageChanged="GetList"
    @onDeleteClick="deleteItem"
    @onEditClick="onEditClick"
    class="mt-n13 moholand-shadow"
  >
    <template #middleButtonTag="{ row }">
      <v-tooltip top color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            dark
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="onDetailsClick(row)"
          >
            <v-icon small dark>mdi-apps</v-icon>
            مشاهده
          </v-btn>
        </template>
        <span dir="ltr">{{ "moholand.com/page/" + row.slug }}</span>
      </v-tooltip>
    </template>
    <template #contentTag="{ row }">
      <td>{{ row.name }}</td>
      <td>{{ row.slug }}</td>
    </template>
  </MyDataTable>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDataTable from "@/components/MyDataTable";

export default {
  created() {
    this.GetList();
    console.log(this.destinationRoute);
  },
  components: {
    MyDataTable,
  },
  methods: {
    GetList: function() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/" + this.$route.meta.api + "/index", {
        params: {
          page: this.$route.query?.page || 1,
          // with: "author",
        },
      })
        .then((response) => {
          this.loading = false;
          this.pagesLength = response.data.last_page;
          this.path = response.data.path;
          this.items = response.data.data.map((val) => ({
            ...val,
            desc: "",
            authorName: val.author
              ? val.author.first_name + " " + val.author.last_name
              : "",
          }));
        })
        .catch((error) => {
          this.loading = false;
          console.log("items", error.response);
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/managepages/edit/" + item.slug,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      window.open("/page/" + item.slug, "_blank");
    },
    onDeleteClick(item) {
      this.$refs.itemsDialog.show(
        "ایا مایل به حذف '" + item.name + "' هستید؟",
        item
      );
    },
    deleteItem(item) {
      if (!this.loading) this.loading = true;
      MyAxios.delete(`/${this.$route.meta.api}/${item.slug}/delete`)
        .then((response) => {
          this.$root.$emit("toast", {
            text: item.name + "با موفقیت حذف شد ",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("toast", {
            text: "مشکلی پیش آمد. لطفا دوباره تلاش کنید.",
          });
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      destinationRoute: this.$route.fullPath,
      loading: true,
      items: [],
      page: 1,
      path: null,
      pathParams: {},
      pagesLength: 1,
      headers: [
        {
          text: "عنوان",
          align: "start",
          value: "name",
        },
        {
          text: "اسلاگ",
          align: "start",
          value: "slug",
        },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
