var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MyDataTable',{staticClass:"mt-n13 moholand-shadow",attrs:{"title":_vm.$route.meta.title + ' ها',"subtitle":'مدیریت ' + _vm.$route.meta.title + ' ها',"addtitle":'ایجاد ' + _vm.$route.meta.title + ' جدید',"addpageRoute":((_vm.$route.path) + "/create" + (_vm.$route.query.page ? '?page=' + _vm.$route.query.page : '')),"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"pagesLength":_vm.pagesLength},on:{"onPageChanged":_vm.GetList,"onDeleteClick":_vm.deleteItem,"onEditClick":_vm.onEditClick},scopedSlots:_vm._u([{key:"middleButtonTag",fn:function(ref){
    var row = ref.row;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onDetailsClick(row)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-apps")]),_vm._v(" مشاهده ")],1)]}}],null,true)},[_c('span',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s("moholand.com/page/" + row.slug))])])]}},{key:"contentTag",fn:function(ref){
    var row = ref.row;
return [_c('td',[_vm._v(_vm._s(row.name))]),_c('td',[_vm._v(_vm._s(row.slug))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }